"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttachedGalleryImageUrlsToJSONTyped = exports.AttachedGalleryImageUrlsToJSON = exports.AttachedGalleryImageUrlsFromJSONTyped = exports.AttachedGalleryImageUrlsFromJSON = void 0;
const runtime_1 = require("../runtime");
function AttachedGalleryImageUrlsFromJSON(json) {
    return AttachedGalleryImageUrlsFromJSONTyped(json, false);
}
exports.AttachedGalleryImageUrlsFromJSON = AttachedGalleryImageUrlsFromJSON;
function AttachedGalleryImageUrlsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'attachedGalleryImageUrl': !(0, runtime_1.exists)(json, 'attachedGalleryImageUrl') ? undefined : json['attachedGalleryImageUrl'],
        'attachedGalleryImageUrl11': !(0, runtime_1.exists)(json, 'attachedGalleryImageUrl_1-1') ? undefined : json['attachedGalleryImageUrl_1-1'],
        'attachedGalleryImageUrl23': !(0, runtime_1.exists)(json, 'attachedGalleryImageUrl_2-3') ? undefined : json['attachedGalleryImageUrl_2-3'],
        'attachedGalleryImageUrl43': !(0, runtime_1.exists)(json, 'attachedGalleryImageUrl_4-3') ? undefined : json['attachedGalleryImageUrl_4-3'],
        'attachedGalleryImageUrl27': !(0, runtime_1.exists)(json, 'attachedGalleryImageUrl_2-7') ? undefined : json['attachedGalleryImageUrl_2-7'],
        'attachedGalleryImageUrl616': !(0, runtime_1.exists)(json, 'attachedGalleryImageUrl_6-16') ? undefined : json['attachedGalleryImageUrl_6-16'],
        'attachedGalleryImageUrl916': !(0, runtime_1.exists)(json, 'attachedGalleryImageUrl_9-16') ? undefined : json['attachedGalleryImageUrl_9-16'],
        'attachedGalleryImageUrl34': !(0, runtime_1.exists)(json, 'attachedGalleryImageUrl_3-4') ? undefined : json['attachedGalleryImageUrl_3-4'],
        'attachedGalleryImageUrl72': !(0, runtime_1.exists)(json, 'attachedGalleryImageUrl_7-2') ? undefined : json['attachedGalleryImageUrl_7-2'],
        'attachedGalleryImageUrl166': !(0, runtime_1.exists)(json, 'attachedGalleryImageUrl_16-6') ? undefined : json['attachedGalleryImageUrl_16-6'],
        'attachedGalleryImageUrl169': !(0, runtime_1.exists)(json, 'attachedGalleryImageUrl_16-9') ? undefined : json['attachedGalleryImageUrl_16-9'],
    };
}
exports.AttachedGalleryImageUrlsFromJSONTyped = AttachedGalleryImageUrlsFromJSONTyped;
function AttachedGalleryImageUrlsToJSON(value) {
    return AttachedGalleryImageUrlsToJSONTyped(value, false);
}
exports.AttachedGalleryImageUrlsToJSON = AttachedGalleryImageUrlsToJSON;
function AttachedGalleryImageUrlsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'attachedGalleryImageUrl': value.attachedGalleryImageUrl,
        'attachedGalleryImageUrl_1-1': value.attachedGalleryImageUrl11,
        'attachedGalleryImageUrl_2-3': value.attachedGalleryImageUrl23,
        'attachedGalleryImageUrl_4-3': value.attachedGalleryImageUrl43,
        'attachedGalleryImageUrl_2-7': value.attachedGalleryImageUrl27,
        'attachedGalleryImageUrl_6-16': value.attachedGalleryImageUrl616,
        'attachedGalleryImageUrl_9-16': value.attachedGalleryImageUrl916,
        'attachedGalleryImageUrl_3-4': value.attachedGalleryImageUrl34,
        'attachedGalleryImageUrl_7-2': value.attachedGalleryImageUrl72,
        'attachedGalleryImageUrl_16-6': value.attachedGalleryImageUrl166,
        'attachedGalleryImageUrl_16-9': value.attachedGalleryImageUrl169,
    };
}
exports.AttachedGalleryImageUrlsToJSONTyped = AttachedGalleryImageUrlsToJSONTyped;
