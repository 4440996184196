"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TvProgramItemToJSONTyped = exports.TvProgramItemToJSON = exports.TvProgramItemFromJSONTyped = exports.TvProgramItemFromJSON = void 0;
const runtime_1 = require("../runtime");
function TvProgramItemFromJSON(json) {
    return TvProgramItemFromJSONTyped(json, false);
}
exports.TvProgramItemFromJSON = TvProgramItemFromJSON;
function TvProgramItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'title': !(0, runtime_1.exists)(json, 'title') ? undefined : json['title'],
        'subtitle': !(0, runtime_1.exists)(json, 'subtitle') ? undefined : json['subtitle'],
        'bodyEpisode': !(0, runtime_1.exists)(json, 'bodyEpisode') ? undefined : json['bodyEpisode'],
        'body': !(0, runtime_1.exists)(json, 'body') ? undefined : json['body'],
        'ageLimit': !(0, runtime_1.exists)(json, 'ageLimit') ? undefined : json['ageLimit'],
        'httplink': !(0, runtime_1.exists)(json, 'httplink') ? undefined : json['httplink'],
        'progDay': !(0, runtime_1.exists)(json, 'progDay') ? undefined : (new Date(json['progDay'])),
        'progDayRaw': !(0, runtime_1.exists)(json, 'progDay') ? undefined : (json['progDay']),
        'progDate': !(0, runtime_1.exists)(json, 'progDate') ? undefined : (new Date(json['progDate'])),
        'progDateRaw': !(0, runtime_1.exists)(json, 'progDate') ? undefined : (json['progDate']),
        'progLength': !(0, runtime_1.exists)(json, 'progLength') ? undefined : json['progLength'],
        'createdYear': !(0, runtime_1.exists)(json, 'createdYear') ? undefined : json['createdYear'],
        'month': !(0, runtime_1.exists)(json, 'month') ? undefined : json['month'],
        'day': !(0, runtime_1.exists)(json, 'day') ? undefined : json['day'],
        'weekday': !(0, runtime_1.exists)(json, 'weekday') ? undefined : json['weekday'],
        'time': !(0, runtime_1.exists)(json, 'time') ? undefined : json['time'],
        'filenameThumb': !(0, runtime_1.exists)(json, 'filenameThumb') ? undefined : json['filenameThumb'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'daytime': !(0, runtime_1.exists)(json, 'daytime') ? undefined : json['daytime'],
        'daytimeAnchor': !(0, runtime_1.exists)(json, 'daytimeAnchor') ? undefined : json['daytimeAnchor'],
        'thumbFilename': !(0, runtime_1.exists)(json, 'thumbFilename') ? undefined : json['thumbFilename'],
        'rtlMostUrl': !(0, runtime_1.exists)(json, 'rtlMostUrl') ? undefined : json['rtlMostUrl'],
        'rtlMostTitle': !(0, runtime_1.exists)(json, 'rtlMostTitle') ? undefined : json['rtlMostTitle'],
    };
}
exports.TvProgramItemFromJSONTyped = TvProgramItemFromJSONTyped;
function TvProgramItemToJSON(value) {
    return TvProgramItemToJSONTyped(value, false);
}
exports.TvProgramItemToJSON = TvProgramItemToJSON;
function TvProgramItemToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'title': value.title,
        'subtitle': value.subtitle,
        'bodyEpisode': value.bodyEpisode,
        'body': value.body,
        'ageLimit': value.ageLimit,
        'httplink': value.httplink,
        'progDay': value.progDay === undefined ? undefined : (value.progDay.toISOString().substr(0, 10)),
        'progDate': value.progDate === undefined ? undefined : (value.progDate.toISOString()),
        'progLength': value.progLength,
        'createdYear': value.createdYear,
        'month': value.month,
        'day': value.day,
        'weekday': value.weekday,
        'time': value.time,
        'filenameThumb': value.filenameThumb,
        'description': value.description,
        'daytime': value.daytime,
        'daytimeAnchor': value.daytimeAnchor,
        'thumbFilename': value.thumbFilename,
        'rtlMostUrl': value.rtlMostUrl,
        'rtlMostTitle': value.rtlMostTitle,
    };
}
exports.TvProgramItemToJSONTyped = TvProgramItemToJSONTyped;
