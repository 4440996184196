"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CastingFormListDataFieldsSingleToJSONTyped = exports.CastingFormListDataFieldsSingleToJSON = exports.CastingFormListDataFieldsSingleFromJSONTyped = exports.CastingFormListDataFieldsSingleFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CastingFormListDataFieldsSingleFromJSON(json) {
    return CastingFormListDataFieldsSingleFromJSONTyped(json, false);
}
exports.CastingFormListDataFieldsSingleFromJSON = CastingFormListDataFieldsSingleFromJSON;
function CastingFormListDataFieldsSingleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'imageUrls': (0, _1.ImageUrlsFromJSON)(json['imageUrls']),
        'type': json['type'],
        'url': json['url'],
        'title': json['title'],
        'appTitle': !(0, runtime_1.exists)(json, 'appTitle') ? undefined : json['appTitle'],
        'formCode': json['formCode'],
    };
}
exports.CastingFormListDataFieldsSingleFromJSONTyped = CastingFormListDataFieldsSingleFromJSONTyped;
function CastingFormListDataFieldsSingleToJSON(value) {
    return CastingFormListDataFieldsSingleToJSONTyped(value, false);
}
exports.CastingFormListDataFieldsSingleToJSON = CastingFormListDataFieldsSingleToJSON;
function CastingFormListDataFieldsSingleToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'imageUrls': (0, _1.ImageUrlsToJSON)(value.imageUrls),
        'type': value.type,
        'url': value.url,
        'title': value.title,
        'appTitle': value.appTitle,
        'formCode': value.formCode,
    };
}
exports.CastingFormListDataFieldsSingleToJSONTyped = CastingFormListDataFieldsSingleToJSONTyped;
