"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchSitesToJSONTyped = exports.SearchSitesToJSON = exports.SearchSitesFromJSONTyped = exports.SearchSitesFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SearchSitesFromJSON(json) {
    return SearchSitesFromJSONTyped(json, false);
}
exports.SearchSitesFromJSON = SearchSitesFromJSON;
function SearchSitesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...json,
        'totalCount': !(0, runtime_1.exists)(json, 'totalCount') ? undefined : json['totalCount'],
        'items': !(0, runtime_1.exists)(json, 'items') ? undefined : (json['items'].map(_1.SiteDataFromJSON)),
    };
}
exports.SearchSitesFromJSONTyped = SearchSitesFromJSONTyped;
function SearchSitesToJSON(value) {
    return SearchSitesToJSONTyped(value, false);
}
exports.SearchSitesToJSON = SearchSitesToJSON;
function SearchSitesToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        'totalCount': value.totalCount,
        'items': value.items === undefined ? undefined : (value.items.map(_1.SiteDataToJSON)),
    };
}
exports.SearchSitesToJSONTyped = SearchSitesToJSONTyped;
