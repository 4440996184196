"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterOptionsToJSONTyped = exports.FilterOptionsToJSON = exports.FilterOptionsFromJSONTyped = exports.FilterOptionsFromJSON = void 0;
const runtime_1 = require("../runtime");
function FilterOptionsFromJSON(json) {
    return FilterOptionsFromJSONTyped(json, false);
}
exports.FilterOptionsFromJSON = FilterOptionsFromJSON;
function FilterOptionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'label': !(0, runtime_1.exists)(json, 'label') ? undefined : json['label'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'value': !(0, runtime_1.exists)(json, 'value') ? undefined : json['value'],
    };
}
exports.FilterOptionsFromJSONTyped = FilterOptionsFromJSONTyped;
function FilterOptionsToJSON(value) {
    return FilterOptionsToJSONTyped(value, false);
}
exports.FilterOptionsToJSON = FilterOptionsToJSON;
function FilterOptionsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'label': value.label,
        'name': value.name,
        'value': value.value,
    };
}
exports.FilterOptionsToJSONTyped = FilterOptionsToJSONTyped;
