"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminContentListResultToJSONTyped = exports.AdminContentListResultToJSON = exports.AdminContentListResultFromJSONTyped = exports.AdminContentListResultFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AdminContentListResultFromJSON(json) {
    return AdminContentListResultFromJSONTyped(json, false);
}
exports.AdminContentListResultFromJSON = AdminContentListResultFromJSON;
function AdminContentListResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'data': (0, _1.AdminContentListDataFromJSON)(json['data']),
        'error': !(0, runtime_1.exists)(json, 'error') ? undefined : (0, _1.ErrorDataFromJSON)(json['error']),
        'result': (0, _1.ResultTypeFromJSON)(json['result']),
        'status': (0, _1.HTTPStatusCodeFromJSON)(json['status']),
    };
}
exports.AdminContentListResultFromJSONTyped = AdminContentListResultFromJSONTyped;
function AdminContentListResultToJSON(value) {
    return AdminContentListResultToJSONTyped(value, false);
}
exports.AdminContentListResultToJSON = AdminContentListResultToJSON;
function AdminContentListResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'data': (0, _1.AdminContentListDataToJSON)(value.data),
        'error': (0, _1.ErrorDataToJSON)(value.error),
        'result': (0, _1.ResultTypeToJSON)(value.result),
        'status': (0, _1.HTTPStatusCodeToJSON)(value.status),
    };
}
exports.AdminContentListResultToJSONTyped = AdminContentListResultToJSONTyped;
