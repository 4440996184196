"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetTvprogramTimeOfDayEnum = exports.GetTvprogramTvChannelEnum = exports.AdditionalApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class AdditionalApi extends runtime.BaseAPI {
    /**
     * Get TV program for channel and date
     * Get TV program for channel and date
     */
    async getTvprogramRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.tvChannel !== undefined) {
            queryParameters['tvChannel'] = requestParameters.tvChannel;
        }
        if (requestParameters.timeOfDay !== undefined) {
            queryParameters['timeOfDay'] = requestParameters.timeOfDay;
        }
        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate.toISOString().substr(0, 10);
        }
        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate.toISOString().substr(0, 10);
        }
        const headerParameters = {};
        if (requestParameters.requestReferer !== undefined && requestParameters.requestReferer !== null) {
            headerParameters['Request-Referer'] = String(requestParameters.requestReferer);
        }
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/tvprogram`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.TvProgramResultFromJSON)(jsonValue));
    }
    /**
     * Get TV program for channel and date
     * Get TV program for channel and date
     */
    async getTvprogram(requestParameters, initOverrides) {
        const response = await this.getTvprogramRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
exports.AdditionalApi = AdditionalApi;
/**
    * @export
    * @enum {string}
    */
var GetTvprogramTvChannelEnum;
(function (GetTvprogramTvChannelEnum) {
    GetTvprogramTvChannelEnum["Rtlklub"] = "rtlklub";
    GetTvprogramTvChannelEnum["Rtlgold"] = "rtlgold";
    GetTvprogramTvChannelEnum["Filmplusz"] = "filmplusz";
    GetTvprogramTvChannelEnum["Cooltv"] = "cooltv";
    GetTvprogramTvChannelEnum["Rtl2"] = "rtl2";
    GetTvprogramTvChannelEnum["Rtlplusz"] = "rtlplusz";
    GetTvprogramTvChannelEnum["Sorozatplusz"] = "sorozatplusz";
    GetTvprogramTvChannelEnum["Muzsikatv"] = "muzsikatv";
})(GetTvprogramTvChannelEnum = exports.GetTvprogramTvChannelEnum || (exports.GetTvprogramTvChannelEnum = {}));
/**
    * @export
    * @enum {string}
    */
var GetTvprogramTimeOfDayEnum;
(function (GetTvprogramTimeOfDayEnum) {
    GetTvprogramTimeOfDayEnum["Am"] = "am";
    GetTvprogramTimeOfDayEnum["Pm"] = "pm";
})(GetTvprogramTimeOfDayEnum = exports.GetTvprogramTimeOfDayEnum || (exports.GetTvprogramTimeOfDayEnum = {}));
