
import Headroom from 'headroom.js'
import { mixins, Options, Ref } from 'vue-property-decorator'

import { isMobile } from '../../../utils'
import FacebookFull from '../icons/FacebookFull.vue'
import Close from '../icons/Close.vue'
import MarketingToolMixin from './MarketingToolMixin'

@Options({
  name: 'FacebookModal',
  components: {
    FacebookFull,
    Close
  }
})

export default class FacebookModal extends mixins(MarketingToolMixin) {
  hasShown = false
  pipeKey = 'facebook-modal'
  storageKey = 'fb-modal-close'

  @Ref('facebookModal')
  readonly modal!: HTMLElement

  private headerOptions = {
    offset: 100,
    tolerance: {
      up: 55,
      down: 0
    }
  }

  get version () {
    if (typeof window !== 'undefined' && window.facebookModalVersion) {
      switch (window.facebookModalVersion) {
        case 'a':
          return 'a'
        case 'b':
          return 'b'
        default:
          return this.getRandomVersion()
      }
    } else {
      return null
    }
  }

  get versionName () {
    if (this.version) {
      return this.version === 'a' ? 'facebook_modal' : 'facebook_modal_exit'
    }
    return null
  }

  get fbPluginSrc () {
    return `https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Frtlponthu&tabs=timeline&width=${this.iframeWidth}&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId`
  }

  get iframeWidth () {
    return isMobile() ? 280 : 314
  }
  
  get isEnabled (): boolean {
    return !!this.version
  }

  getRandomVersion () {
    if (window && window.gaGlobal) {
      const gaId = window.gaGlobal.vid
      const isOdd = (parseInt(gaId[gaId.length - 1])) % 2 === 1
      return isOdd ? 'a' : 'b'
    } else {
      return (Math.random() < 0.5) ? 'a' : 'b'
    }
  }

  triggerMobile () {
    if (isMobile()) {
      const headroom = new Headroom(this.modal, {
        ...this.headerOptions,
        onPin: () => {
          if (!this.hasShown) {
            this.hasShown = true
            this.measure('view', this.versionName)
            this.setExpire()
          }
        }
      })
      headroom.init()
    }
  }

  triggerDesktop (event: MouseEvent) {
    if (!isMobile() && !this.hasShown) {
      if (event.clientY <= 0 || event.clientX <= 0 || (event.clientX >= window.innerWidth || event.clientY >= window.innerHeight)) {
        this.hasShown = true
        this.measure('view', this.versionName)
        this.setExpire()
      }
    }
  }

  click (version?: string) {
    this.isShow = false
    if (version) {
      this.measure('select', this.versionName)
    }
  }

  close () {
    this.isShow = false
    this.measure('close', this.versionName)
  }

  async mounted () {
    this.$nextTick(() => {
      if (this.isAvailable) {
        this.triggerMobile()
        document.addEventListener('mouseout', this.triggerDesktop)
      }
    })
  }

  unmounted () {
    document.removeEventListener('mouseout', this.triggerDesktop)
  }
}
