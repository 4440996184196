"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateTokenDataToJSONTyped = exports.GenerateTokenDataToJSON = exports.GenerateTokenDataFromJSONTyped = exports.GenerateTokenDataFromJSON = void 0;
function GenerateTokenDataFromJSON(json) {
    return GenerateTokenDataFromJSONTyped(json, false);
}
exports.GenerateTokenDataFromJSON = GenerateTokenDataFromJSON;
function GenerateTokenDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'authorizationCode': json['authorizationCode'],
    };
}
exports.GenerateTokenDataFromJSONTyped = GenerateTokenDataFromJSONTyped;
function GenerateTokenDataToJSON(value) {
    return GenerateTokenDataToJSONTyped(value, false);
}
exports.GenerateTokenDataToJSON = GenerateTokenDataToJSON;
function GenerateTokenDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'authorizationCode': value.authorizationCode,
    };
}
exports.GenerateTokenDataToJSONTyped = GenerateTokenDataToJSONTyped;
