
import { mixins, Options, Prop, Watch } from 'vue-property-decorator'
import { RouteLocationRaw } from 'vue-router'
import { Action } from 'vuex-class'

import { GigyaError, RegisterRequest, UserResponse } from '../../gigya'
import { USER_ACTION, USER_NAMESPACE } from '../../store'
import Checkbox from '../components/auth/Checkbox.vue'
import DateInput from '../components/auth/DateInput.vue'
import PasswordInput from '../components/auth/PasswordInput.vue'
import Select from '../components/auth/Select.vue'
import TextInput from '../components/auth/TextInput.vue'
import Apple from '../components/icons/Apple.vue'
import Exclamation from '../components/icons/Exclamation.vue'
import Eye from '../components/icons/Eye.vue'
import FacebookFull from '../components/icons/FacebookFull.vue'
import Google from '../components/icons/Google.vue'
import Modal from '../components/Modal.vue'
import SocialRegFinish from '../components/SocialRegFinish.vue'
import AuthPageMixin from '../mixins/AuthPageMixin'

@Options({
  name: 'RegisterPage',
  components: {
    Eye,
    FacebookFull,
    Google,
    Apple,
    Exclamation,
    Modal,
    DateInput,
    Checkbox,
    PasswordInput,
    TextInput,
    Select,
    SocialRegFinish
  }
})
export default class RegisterPage extends mixins(AuthPageMixin) {
  @Action(USER_ACTION.REGISTER, { namespace: USER_NAMESPACE })
  registerUserAction!: (params: RegisterRequest) => Promise<UserResponse>

  @Prop({
    type: [String, Object],
    default: () => ({ name: 'login' })
  })
  readonly loginUrl!: RouteLocationRaw

  @Prop({
    type: [String, Object],
    default: () => ({ name: 'password_reset' })
  })
  readonly passwordUrl!: RouteLocationRaw

  @Prop({
    type: Boolean,
    default: false
  })
  readonly socialDisabled!: boolean

  registerData: RegisterRequest = {
    email: '',
    password: '',
    profile: {
      firstName: '',
      lastName: '',
      gender: '',
      birthDay: 0,
      birthMonth: 0,
      birthYear: 0
    },
    data: {
      RTLHU_Consent: false,
      RTLHU_Newsletter: false
    }
  }

  birthDate = ''
  modalIsOpen = false

  mounted () {
    window.addEventListener('message', this.onPostMessage, false)
  }

  beforeUnmount () {
    window.removeEventListener('message', this.onPostMessage)
  }

  @Watch('birthDate')
  updateBirthData (value: string): void {
    const date = new Date(value)
    this.registerData.profile.birthDay = date.getDate()
    this.registerData.profile.birthMonth = date.getMonth() + 1
    this.registerData.profile.birthYear = date.getFullYear()
  }

  register () {
    if (this.checkRequiredFields() && this.validateForm()) {
      this.registerUserAction(this.registerData)
        .then(() => {
          this.measureReg('email')
          this.$router.push(this.successUrl)
        })
        .catch((e) => {
          console.error(e)
          if (e instanceof GigyaError && e.validationErrors.length) {
            const emailInUse = e.validationErrors.find(err => err.fieldName === 'email' && err.errorCode === 400003)
            if (emailInUse) {
              this.addFormError({ field: 'email', msg: 'Az e-mail cím már használatban van' })
            }
          } else {
            this.addFormError({ field: 'error', msg: 'Hiba történt a regisztráció során. Kérjük próbáld meg újra!' })
          }
          this.scrollUp()
        })
    } else {
      this.scrollUp()
    }
  }

  checkRequiredFields (): boolean {
    return this.checkProperties(this.registerData as never, ['data.RTLHU_Newsletter'])
  }

  validateForm (): boolean {
    this.clearFormErrors()
    this.validateEmail(this.registerData.email, 'email')
    this.validatePassword(this.registerData.password, 'password', true)
    this.validateRequiredString(this.registerData.profile?.lastName || '', 'lastName', 'A vezetéknév kitöltése kötelező')
    this.validateRequiredString(this.registerData.profile?.firstName || '', 'firstName', 'A keresztnév kitöltése kötelező')
    this.validateBirthDate()
    this.validateRequiredString(this.registerData.profile?.gender || '', 'gender', 'A nemed megadása kötelező')
    this.validateRequiredBoolean(this.registerData?.data?.RTLHU_Consent || false, 'RTLHU_Consent', 'Az adatkezelési tájékoztató és az általános szerződési feltételek elfogadása kötelező')

    return !this.hasFormError()
  }

  validateBirthDate () {
    const d = this.registerData.profile?.birthDay
    const m = this.registerData.profile?.birthMonth
    const y = this.registerData.profile?.birthYear

    if (!d || !m || !y) {
      this.addFormError({ field: 'birthDate', msg: 'A születési idő megadása kötelező' })
    }
  }

  openModal () {
    this.clearFormErrors()
    this.modalIsOpen = true
  }
}
