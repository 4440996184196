"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuItemToJSONTyped = exports.MenuItemToJSON = exports.MenuItemFromJSONTyped = exports.MenuItemFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function MenuItemFromJSON(json) {
    return MenuItemFromJSONTyped(json, false);
}
exports.MenuItemFromJSON = MenuItemFromJSON;
function MenuItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'imageUrls': !(0, runtime_1.exists)(json, 'imageUrls') ? undefined : (0, _1.ImageUrlsFromJSON)(json['imageUrls']),
        'label': !(0, runtime_1.exists)(json, 'label') ? undefined : json['label'],
        'targetUrl': !(0, runtime_1.exists)(json, 'targetUrl') ? undefined : json['targetUrl'],
        'target': !(0, runtime_1.exists)(json, 'target') ? undefined : json['target'],
        'menuItems': !(0, runtime_1.exists)(json, 'menuItems') ? undefined : (json['menuItems'].map(MenuItemFromJSON)),
    };
}
exports.MenuItemFromJSONTyped = MenuItemFromJSONTyped;
function MenuItemToJSON(value) {
    return MenuItemToJSONTyped(value, false);
}
exports.MenuItemToJSON = MenuItemToJSON;
function MenuItemToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'imageUrls': (0, _1.ImageUrlsToJSON)(value.imageUrls),
        'label': value.label,
        'targetUrl': value.targetUrl,
        'target': value.target,
        'menuItems': value.menuItems === undefined ? undefined : (value.menuItems.map(MenuItemToJSON)),
    };
}
exports.MenuItemToJSONTyped = MenuItemToJSONTyped;
