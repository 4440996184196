"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CastingFormGroupsListDataToJSONTyped = exports.CastingFormGroupsListDataToJSON = exports.CastingFormGroupsListDataFromJSONTyped = exports.CastingFormGroupsListDataFromJSON = void 0;
function CastingFormGroupsListDataFromJSON(json) {
    return CastingFormGroupsListDataFromJSONTyped(json, false);
}
exports.CastingFormGroupsListDataFromJSON = CastingFormGroupsListDataFromJSON;
function CastingFormGroupsListDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'items': json['items'],
    };
}
exports.CastingFormGroupsListDataFromJSONTyped = CastingFormGroupsListDataFromJSONTyped;
function CastingFormGroupsListDataToJSON(value) {
    return CastingFormGroupsListDataToJSONTyped(value, false);
}
exports.CastingFormGroupsListDataToJSON = CastingFormGroupsListDataToJSON;
function CastingFormGroupsListDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'items': value.items,
    };
}
exports.CastingFormGroupsListDataToJSONTyped = CastingFormGroupsListDataToJSONTyped;
