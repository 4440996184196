import { Cookie } from '../utils/cookie.util'
import { UserData } from './'

export interface GigyaStorageData {
  lToken?: string | null
  rToken?: string | null
  user?: UserData | null
  jwt?: string | null
}

const GIGYA_STORAGE_KEY = 'gigya'
const GIGYA_SESSION_KEY = 'gigya_session'
const GIGYA_SESSION_FLAG = '1'

export class GigyaStorage {
  private data: GigyaStorageData = {}

  constructor () {
    this.load()
  }

  public load (): void {
    const CookieData = Cookie.get(GIGYA_STORAGE_KEY)
    const SessionFlag = Cookie.get(GIGYA_SESSION_KEY)

    if (CookieData && SessionFlag) {
      Object.assign(this.data, JSON.parse(CookieData))
    } else {
      this.clear()
    }
  }

  public save (): void {
    const hostDomain = window.location.hostname.split('.').slice(-2).join('.')
    const expireAt = this?.data?.user?.sessionExpiration ? new Date(this.data.user.sessionExpiration) : null
    Cookie.set(GIGYA_SESSION_KEY, GIGYA_SESSION_FLAG, null, expireAt, hostDomain)
    Cookie.set(GIGYA_STORAGE_KEY, JSON.stringify(this.data), null, expireAt, hostDomain)
  }

  public get<K extends keyof GigyaStorageData> (key: K): GigyaStorageData[K] {
    return this.data[key]
  }

  public set<K extends keyof GigyaStorageData> (key: K, value: GigyaStorageData[K]): GigyaStorageData[K] {
    this.data[key] = value
    this.save()

    return value
  }

  public unset<K extends keyof GigyaStorageData> (key: K): void {
    this.data[key] = null
    this.save()
  }

  public clear (): void {
    for (const k of Object.keys(this.data)) {
      this.unset(k as keyof GigyaStorageData)
    }

    Cookie.delete(GIGYA_SESSION_KEY)
    Cookie.delete(GIGYA_STORAGE_KEY)
  }
}
