"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagResultToJSONTyped = exports.TagResultToJSON = exports.TagResultFromJSONTyped = exports.TagResultFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TagResultFromJSON(json) {
    return TagResultFromJSONTyped(json, false);
}
exports.TagResultFromJSON = TagResultFromJSON;
function TagResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'result': (0, _1.ResultTypeFromJSON)(json['result']),
        'status': (0, _1.HTTPStatusCodeFromJSON)(json['status']),
        'data': (0, _1.TagFromJSON)(json['data']),
        'error': !(0, runtime_1.exists)(json, 'error') ? undefined : (0, _1.ErrorDataFromJSON)(json['error']),
    };
}
exports.TagResultFromJSONTyped = TagResultFromJSONTyped;
function TagResultToJSON(value) {
    return TagResultToJSONTyped(value, false);
}
exports.TagResultToJSON = TagResultToJSON;
function TagResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'result': (0, _1.ResultTypeToJSON)(value.result),
        'status': (0, _1.HTTPStatusCodeToJSON)(value.status),
        'data': (0, _1.TagToJSON)(value.data),
        'error': (0, _1.ErrorDataToJSON)(value.error),
    };
}
exports.TagResultToJSONTyped = TagResultToJSONTyped;
