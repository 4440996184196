import { UserData } from '../../../gigya'

export interface UserState {
  user: UserData | null
}

export enum USER_GETTER {
  API = 'api',
  USER = 'getUser'
}

export enum USER_MUTATION {
  USER = 'setUser'
}

export enum USER_ACTION {
  LOGIN = 'login',
  LOGOUT = 'logout',
  REGISTER = 'register',
  RESET_PASSWORD = 'resetPassword',
  SOCIAL = 'social',
  ACCOUNT_INFO = 'accountInfo',
  USER_INFO = 'userInfo',
  NOTIFY_LOGIN = 'notifyLogin',
  UPDATE_ACCOUNT = 'updateAccount',
  JWT = 'jwt',
  SET_AVATAR = 'setAvatar',
  DELETE_ACCOUNT = 'deleteAccount',
  IS_AVAILABLE = 'isAvailable',
  SEND_VERIFICATION = 'sendVerification',
  LINK_ACCOUNT = 'linkAccount',
  FINALIZE_REGISTRATION = 'finalizeRegistration',
  FORCED_LOGOUT = 'forcedLogout'
}

export const USER_NAMESPACE = 'user'
