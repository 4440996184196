"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdditionalJsonToJSONTyped = exports.AdditionalJsonToJSON = exports.AdditionalJsonFromJSONTyped = exports.AdditionalJsonFromJSON = void 0;
const runtime_1 = require("../runtime");
function AdditionalJsonFromJSON(json) {
    return AdditionalJsonFromJSONTyped(json, false);
}
exports.AdditionalJsonFromJSON = AdditionalJsonFromJSON;
function AdditionalJsonFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...json,
        'celebFacebook': !(0, runtime_1.exists)(json, 'celebFacebook') ? undefined : json['celebFacebook'],
        'celebTwitter': !(0, runtime_1.exists)(json, 'celebTwitter') ? undefined : json['celebTwitter'],
        'celebGoogle': !(0, runtime_1.exists)(json, 'celebGoogle') ? undefined : json['celebGoogle'],
        'celebInstagram': !(0, runtime_1.exists)(json, 'celebInstagram') ? undefined : json['celebInstagram'],
        'celebTumblr': !(0, runtime_1.exists)(json, 'celebTumblr') ? undefined : json['celebTumblr'],
        'celebPhone': !(0, runtime_1.exists)(json, 'celebPhone') ? undefined : json['celebPhone'],
        'introductionVideo': !(0, runtime_1.exists)(json, 'introductionVideo') ? undefined : json['introductionVideo'],
    };
}
exports.AdditionalJsonFromJSONTyped = AdditionalJsonFromJSONTyped;
function AdditionalJsonToJSON(value) {
    return AdditionalJsonToJSONTyped(value, false);
}
exports.AdditionalJsonToJSON = AdditionalJsonToJSON;
function AdditionalJsonToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        'celebFacebook': value.celebFacebook,
        'celebTwitter': value.celebTwitter,
        'celebGoogle': value.celebGoogle,
        'celebInstagram': value.celebInstagram,
        'celebTumblr': value.celebTumblr,
        'celebPhone': value.celebPhone,
        'introductionVideo': value.introductionVideo,
    };
}
exports.AdditionalJsonToJSONTyped = AdditionalJsonToJSONTyped;
