"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminRelatedPagesDataPayloadToJSONTyped = exports.AdminRelatedPagesDataPayloadToJSON = exports.AdminRelatedPagesDataPayloadFromJSONTyped = exports.AdminRelatedPagesDataPayloadFromJSON = void 0;
const runtime_1 = require("../runtime");
function AdminRelatedPagesDataPayloadFromJSON(json) {
    return AdminRelatedPagesDataPayloadFromJSONTyped(json, false);
}
exports.AdminRelatedPagesDataPayloadFromJSON = AdminRelatedPagesDataPayloadFromJSON;
function AdminRelatedPagesDataPayloadFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'limit': !(0, runtime_1.exists)(json, 'limit') ? undefined : json['limit'],
        'offset': !(0, runtime_1.exists)(json, 'offset') ? undefined : json['offset'],
        'layoutId': !(0, runtime_1.exists)(json, 'layoutId') ? undefined : json['layoutId'],
        'disconnectedPages': !(0, runtime_1.exists)(json, 'disconnectedPages') ? undefined : json['disconnectedPages'],
    };
}
exports.AdminRelatedPagesDataPayloadFromJSONTyped = AdminRelatedPagesDataPayloadFromJSONTyped;
function AdminRelatedPagesDataPayloadToJSON(value) {
    return AdminRelatedPagesDataPayloadToJSONTyped(value, false);
}
exports.AdminRelatedPagesDataPayloadToJSON = AdminRelatedPagesDataPayloadToJSON;
function AdminRelatedPagesDataPayloadToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'limit': value.limit,
        'offset': value.offset,
        'layoutId': value.layoutId,
        'disconnectedPages': value.disconnectedPages,
    };
}
exports.AdminRelatedPagesDataPayloadToJSONTyped = AdminRelatedPagesDataPayloadToJSONTyped;
