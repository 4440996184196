"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostVoteToJSONTyped = exports.PostVoteToJSON = exports.PostVoteFromJSONTyped = exports.PostVoteFromJSON = void 0;
const runtime_1 = require("../runtime");
function PostVoteFromJSON(json) {
    return PostVoteFromJSONTyped(json, false);
}
exports.PostVoteFromJSON = PostVoteFromJSON;
function PostVoteFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...json,
        'voteId': !(0, runtime_1.exists)(json, 'voteId') ? undefined : json['voteId'],
        'answerId': !(0, runtime_1.exists)(json, 'answerId') ? undefined : json['answerId'],
    };
}
exports.PostVoteFromJSONTyped = PostVoteFromJSONTyped;
function PostVoteToJSON(value) {
    return PostVoteToJSONTyped(value, false);
}
exports.PostVoteToJSON = PostVoteToJSON;
function PostVoteToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        'voteId': value.voteId,
        'answerId': value.answerId,
    };
}
exports.PostVoteToJSONTyped = PostVoteToJSONTyped;
