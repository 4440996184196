"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinimalSiteDataToJSONTyped = exports.MinimalSiteDataToJSON = exports.MinimalSiteDataFromJSONTyped = exports.MinimalSiteDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function MinimalSiteDataFromJSON(json) {
    return MinimalSiteDataFromJSONTyped(json, false);
}
exports.MinimalSiteDataFromJSON = MinimalSiteDataFromJSON;
function MinimalSiteDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'folderId': json['folderId'],
        'name': json['name'],
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : (0, _1.SiteTypesFromJSON)(json['type']),
        'subtype': !(0, runtime_1.exists)(json, 'subtype') ? undefined : json['subtype'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'label': !(0, runtime_1.exists)(json, 'label') ? undefined : json['label'],
    };
}
exports.MinimalSiteDataFromJSONTyped = MinimalSiteDataFromJSONTyped;
function MinimalSiteDataToJSON(value) {
    return MinimalSiteDataToJSONTyped(value, false);
}
exports.MinimalSiteDataToJSON = MinimalSiteDataToJSON;
function MinimalSiteDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'folderId': value.folderId,
        'name': value.name,
        'type': (0, _1.SiteTypesToJSON)(value.type),
        'subtype': value.subtype,
        'url': value.url,
        'label': value.label,
    };
}
exports.MinimalSiteDataToJSONTyped = MinimalSiteDataToJSONTyped;
