"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdoKeysMediaZonesToJSONTyped = exports.AdoKeysMediaZonesToJSON = exports.AdoKeysMediaZonesFromJSONTyped = exports.AdoKeysMediaZonesFromJSON = void 0;
const runtime_1 = require("../runtime");
function AdoKeysMediaZonesFromJSON(json) {
    return AdoKeysMediaZonesFromJSONTyped(json, false);
}
exports.AdoKeysMediaZonesFromJSON = AdoKeysMediaZonesFromJSON;
function AdoKeysMediaZonesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...json,
        '_11box': !(0, runtime_1.exists)(json, '11Box') ? undefined : json['11Box'],
        '_300x2501': !(0, runtime_1.exists)(json, '300x2501') ? undefined : json['300x2501'],
        '_480x4801': !(0, runtime_1.exists)(json, '480x4801') ? undefined : json['480x4801'],
        '_480x4802': !(0, runtime_1.exists)(json, '480x4802') ? undefined : json['480x4802'],
        '_480x4803': !(0, runtime_1.exists)(json, '480x4803') ? undefined : json['480x4803'],
        '_640x3601': !(0, runtime_1.exists)(json, '640x3601') ? undefined : json['640x3601'],
        '_960x2501': !(0, runtime_1.exists)(json, '960x2501') ? undefined : json['960x2501'],
        '_960x2502': !(0, runtime_1.exists)(json, '960x2502') ? undefined : json['960x2502'],
        '_960x2503': !(0, runtime_1.exists)(json, '960x2503') ? undefined : json['960x2503'],
        '_960x2504': !(0, runtime_1.exists)(json, '960x2504') ? undefined : json['960x2504'],
        'dInterstitial': !(0, runtime_1.exists)(json, 'DInterstitial') ? undefined : json['DInterstitial'],
        'dPeelaway': !(0, runtime_1.exists)(json, 'DPeelaway') ? undefined : json['DPeelaway'],
        'landscape': !(0, runtime_1.exists)(json, 'Landscape') ? undefined : json['Landscape'],
        'mobilMagic': !(0, runtime_1.exists)(json, 'mobilMagic') ? undefined : json['mobilMagic'],
        'skyLeft': !(0, runtime_1.exists)(json, 'SkyLeft') ? undefined : json['SkyLeft'],
        'skyRight': !(0, runtime_1.exists)(json, 'SkyRight') ? undefined : json['SkyRight'],
        'special1': !(0, runtime_1.exists)(json, 'Special1') ? undefined : json['Special1'],
        'special3': !(0, runtime_1.exists)(json, 'Special3') ? undefined : json['Special3'],
        'special4': !(0, runtime_1.exists)(json, 'Special4') ? undefined : json['Special4'],
    };
}
exports.AdoKeysMediaZonesFromJSONTyped = AdoKeysMediaZonesFromJSONTyped;
function AdoKeysMediaZonesToJSON(value) {
    return AdoKeysMediaZonesToJSONTyped(value, false);
}
exports.AdoKeysMediaZonesToJSON = AdoKeysMediaZonesToJSON;
function AdoKeysMediaZonesToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        '11Box': value._11box,
        '300x2501': value._300x2501,
        '480x4801': value._480x4801,
        '480x4802': value._480x4802,
        '480x4803': value._480x4803,
        '640x3601': value._640x3601,
        '960x2501': value._960x2501,
        '960x2502': value._960x2502,
        '960x2503': value._960x2503,
        '960x2504': value._960x2504,
        'DInterstitial': value.dInterstitial,
        'DPeelaway': value.dPeelaway,
        'Landscape': value.landscape,
        'mobilMagic': value.mobilMagic,
        'SkyLeft': value.skyLeft,
        'SkyRight': value.skyRight,
        'Special1': value.special1,
        'Special3': value.special3,
        'Special4': value.special4,
    };
}
exports.AdoKeysMediaZonesToJSONTyped = AdoKeysMediaZonesToJSONTyped;
