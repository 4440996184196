"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingVariablesToJSONTyped = exports.TrackingVariablesToJSON = exports.TrackingVariablesFromJSONTyped = exports.TrackingVariablesFromJSON = void 0;
const runtime_1 = require("../runtime");
function TrackingVariablesFromJSON(json) {
    return TrackingVariablesFromJSONTyped(json, false);
}
exports.TrackingVariablesFromJSON = TrackingVariablesFromJSON;
function TrackingVariablesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'trackingEnabled': !(0, runtime_1.exists)(json, 'trackingEnabled') ? undefined : json['trackingEnabled'],
        'pageId': !(0, runtime_1.exists)(json, 'pageId') ? undefined : json['pageId'],
        'contentId': !(0, runtime_1.exists)(json, 'contentId') ? undefined : json['contentId'],
        'siteName': !(0, runtime_1.exists)(json, 'siteName') ? undefined : json['siteName'],
        'ageRating': !(0, runtime_1.exists)(json, 'ageRating') ? undefined : json['ageRating'],
        'folderId': !(0, runtime_1.exists)(json, 'folderId') ? undefined : json['folderId'],
        'nsfwFlag': !(0, runtime_1.exists)(json, 'nsfwFlag') ? undefined : json['nsfwFlag'],
        'authorName': !(0, runtime_1.exists)(json, 'authorName') ? undefined : json['authorName'],
        'duration': !(0, runtime_1.exists)(json, 'duration') ? undefined : json['duration'],
        'pageTitle': !(0, runtime_1.exists)(json, 'pageTitle') ? undefined : json['pageTitle'],
        'programId': !(0, runtime_1.exists)(json, 'programId') ? undefined : json['programId'],
        'pubDate': !(0, runtime_1.exists)(json, 'pubDate') ? undefined : json['pubDate'],
        'pageType': !(0, runtime_1.exists)(json, 'pageType') ? undefined : json['pageType'],
    };
}
exports.TrackingVariablesFromJSONTyped = TrackingVariablesFromJSONTyped;
function TrackingVariablesToJSON(value) {
    return TrackingVariablesToJSONTyped(value, false);
}
exports.TrackingVariablesToJSON = TrackingVariablesToJSON;
function TrackingVariablesToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'trackingEnabled': value.trackingEnabled,
        'pageId': value.pageId,
        'contentId': value.contentId,
        'siteName': value.siteName,
        'ageRating': value.ageRating,
        'folderId': value.folderId,
        'nsfwFlag': value.nsfwFlag,
        'authorName': value.authorName,
        'duration': value.duration,
        'pageTitle': value.pageTitle,
        'programId': value.programId,
        'pubDate': value.pubDate,
        'pageType': value.pageType,
    };
}
exports.TrackingVariablesToJSONTyped = TrackingVariablesToJSONTyped;
