"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentListQueryOrderToJSONTyped = exports.ContentListQueryOrderToJSON = exports.ContentListQueryOrderFromJSONTyped = exports.ContentListQueryOrderFromJSON = exports.ContentListQueryOrderDirectionEnum = exports.ContentListQueryOrderFieldEnum = void 0;
const runtime_1 = require("../runtime");
/**
* @export
* @enum {string}
*/
var ContentListQueryOrderFieldEnum;
(function (ContentListQueryOrderFieldEnum) {
    ContentListQueryOrderFieldEnum["ReleaseDate"] = "release_date";
    ContentListQueryOrderFieldEnum["Views"] = "views";
    ContentListQueryOrderFieldEnum["Priority"] = "priority";
    ContentListQueryOrderFieldEnum["Topn"] = "topn";
})(ContentListQueryOrderFieldEnum = exports.ContentListQueryOrderFieldEnum || (exports.ContentListQueryOrderFieldEnum = {})); /**
* @export
* @enum {string}
*/
var ContentListQueryOrderDirectionEnum;
(function (ContentListQueryOrderDirectionEnum) {
    ContentListQueryOrderDirectionEnum["Asc"] = "asc";
    ContentListQueryOrderDirectionEnum["Desc"] = "desc";
    ContentListQueryOrderDirectionEnum["Random"] = "random";
})(ContentListQueryOrderDirectionEnum = exports.ContentListQueryOrderDirectionEnum || (exports.ContentListQueryOrderDirectionEnum = {}));
function ContentListQueryOrderFromJSON(json) {
    return ContentListQueryOrderFromJSONTyped(json, false);
}
exports.ContentListQueryOrderFromJSON = ContentListQueryOrderFromJSON;
function ContentListQueryOrderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'field': !(0, runtime_1.exists)(json, 'field') ? undefined : json['field'],
        'direction': json['direction'],
    };
}
exports.ContentListQueryOrderFromJSONTyped = ContentListQueryOrderFromJSONTyped;
function ContentListQueryOrderToJSON(value) {
    return ContentListQueryOrderToJSONTyped(value, false);
}
exports.ContentListQueryOrderToJSON = ContentListQueryOrderToJSON;
function ContentListQueryOrderToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'field': value.field,
        'direction': value.direction,
    };
}
exports.ContentListQueryOrderToJSONTyped = ContentListQueryOrderToJSONTyped;
