"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinimalContentParamsToJSONTyped = exports.MinimalContentParamsToJSON = exports.MinimalContentParamsFromJSONTyped = exports.MinimalContentParamsFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function MinimalContentParamsFromJSON(json) {
    return MinimalContentParamsFromJSONTyped(json, false);
}
exports.MinimalContentParamsFromJSON = MinimalContentParamsFromJSON;
function MinimalContentParamsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...json,
        'season': !(0, runtime_1.exists)(json, 'season') ? undefined : json['season'],
        'episode': !(0, runtime_1.exists)(json, 'episode') ? undefined : json['episode'],
        'duration': !(0, runtime_1.exists)(json, 'duration') ? undefined : json['duration'],
        'embedVideoUrl': !(0, runtime_1.exists)(json, 'embedVideoUrl') ? undefined : json['embedVideoUrl'],
        'rtlMostEpisodeUrl': !(0, runtime_1.exists)(json, 'rtlMostEpisodeUrl') ? undefined : json['rtlMostEpisodeUrl'],
        'publishedOnSixPlay': !(0, runtime_1.exists)(json, 'publishedOnSixPlay') ? undefined : json['publishedOnSixPlay'],
        'backgroundImageUrls': !(0, runtime_1.exists)(json, 'backgroundImageUrls') ? undefined : (0, _1.BackgroundImageUrlsFromJSON)(json['backgroundImageUrls']),
        'viewTrackingUrl': !(0, runtime_1.exists)(json, 'viewTrackingUrl') ? undefined : json['viewTrackingUrl'],
        'clickTrackingUrl': !(0, runtime_1.exists)(json, 'clickTrackingUrl') ? undefined : json['clickTrackingUrl'],
        'backgroundColor': !(0, runtime_1.exists)(json, 'backgroundColor') ? undefined : json['backgroundColor'],
        'rtlMostEpisodeTitle': !(0, runtime_1.exists)(json, 'rtlMostEpisodeTitle') ? undefined : json['rtlMostEpisodeTitle'],
        'rtlMostEpisode': !(0, runtime_1.exists)(json, 'rtlMostEpisode') ? undefined : json['rtlMostEpisode'],
        'rtlMostSeason': !(0, runtime_1.exists)(json, 'rtlMostSeason') ? undefined : json['rtlMostSeason'],
        'position': !(0, runtime_1.exists)(json, 'position') ? undefined : json['position'],
        'tabs': !(0, runtime_1.exists)(json, 'tabs') ? undefined : (json['tabs'].map(_1.CelebTabsFromJSON)),
        'showVoteResults': !(0, runtime_1.exists)(json, 'showVoteResults') ? undefined : json['showVoteResults'],
        'answers': !(0, runtime_1.exists)(json, 'answers') ? undefined : (json['answers'].map(_1.VoteAnswerFromJSON)),
        'hightlightedTags': !(0, runtime_1.exists)(json, 'hightlightedTags') ? undefined : (json['hightlightedTags'].map(_1.TagFromJSON)),
        'alternativeImageUrls': !(0, runtime_1.exists)(json, 'alternativeImageUrls') ? undefined : (0, _1.AlternativeImageUrlsFromJSON)(json['alternativeImageUrls']),
        'withVideo': !(0, runtime_1.exists)(json, 'withVideo') ? undefined : json['withVideo'],
        'isLiveFeed': !(0, runtime_1.exists)(json, 'isLiveFeed') ? undefined : json['isLiveFeed'],
        'hasAttachedGallery': !(0, runtime_1.exists)(json, 'hasAttachedGallery') ? undefined : json['hasAttachedGallery'],
    };
}
exports.MinimalContentParamsFromJSONTyped = MinimalContentParamsFromJSONTyped;
function MinimalContentParamsToJSON(value) {
    return MinimalContentParamsToJSONTyped(value, false);
}
exports.MinimalContentParamsToJSON = MinimalContentParamsToJSON;
function MinimalContentParamsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        'season': value.season,
        'episode': value.episode,
        'duration': value.duration,
        'embedVideoUrl': value.embedVideoUrl,
        'rtlMostEpisodeUrl': value.rtlMostEpisodeUrl,
        'publishedOnSixPlay': value.publishedOnSixPlay,
        'backgroundImageUrls': (0, _1.BackgroundImageUrlsToJSON)(value.backgroundImageUrls),
        'viewTrackingUrl': value.viewTrackingUrl,
        'clickTrackingUrl': value.clickTrackingUrl,
        'backgroundColor': value.backgroundColor,
        'rtlMostEpisodeTitle': value.rtlMostEpisodeTitle,
        'rtlMostEpisode': value.rtlMostEpisode,
        'rtlMostSeason': value.rtlMostSeason,
        'position': value.position,
        'tabs': value.tabs === undefined ? undefined : (value.tabs.map(_1.CelebTabsToJSON)),
        'showVoteResults': value.showVoteResults,
        'answers': value.answers === undefined ? undefined : (value.answers.map(_1.VoteAnswerToJSON)),
        'hightlightedTags': value.hightlightedTags === undefined ? undefined : (value.hightlightedTags.map(_1.TagToJSON)),
        'alternativeImageUrls': (0, _1.AlternativeImageUrlsToJSON)(value.alternativeImageUrls),
        'withVideo': value.withVideo,
        'isLiveFeed': value.isLiveFeed,
        'hasAttachedGallery': value.hasAttachedGallery,
    };
}
exports.MinimalContentParamsToJSONTyped = MinimalContentParamsToJSONTyped;
