"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListSitesIntervalEnum = exports.SiteApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class SiteApi extends runtime.BaseAPI {
    /**
     * Gets the details of a single instance of a `Site`.
     * Get a Site
     */
    async getSiteByIdRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling getSiteById.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (requestParameters.requestReferer !== undefined && requestParameters.requestReferer !== null) {
            headerParameters['Request-Referer'] = String(requestParameters.requestReferer);
        }
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/site/{siteId}`.replace(`{${"siteId"}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.SiteResultFromJSON)(jsonValue));
    }
    /**
     * Gets the details of a single instance of a `Site`.
     * Get a Site
     */
    async getSiteById(requestParameters, initOverrides) {
        const response = await this.getSiteByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Gets list of the Sites.
     * List Sites
     */
    async listSitesRaw(requestParameters, initOverrides) {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling listSites.');
        }
        const queryParameters = {};
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        if (requestParameters.channel !== undefined) {
            queryParameters['channel'] = requestParameters.channel;
        }
        if (requestParameters.order) {
            queryParameters['order'] = requestParameters.order;
        }
        if (requestParameters.interval !== undefined) {
            queryParameters['interval'] = requestParameters.interval;
        }
        if (requestParameters.siteIds) {
            queryParameters['siteIds'] = requestParameters.siteIds;
        }
        const headerParameters = {};
        if (requestParameters.requestReferer !== undefined && requestParameters.requestReferer !== null) {
            headerParameters['Request-Referer'] = String(requestParameters.requestReferer);
        }
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/site/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.SiteListResultFromJSON)(jsonValue));
    }
    /**
     * Gets list of the Sites.
     * List Sites
     */
    async listSites(requestParameters, initOverrides) {
        const response = await this.listSitesRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
exports.SiteApi = SiteApi;
/**
    * @export
    * @enum {string}
    */
var ListSitesIntervalEnum;
(function (ListSitesIntervalEnum) {
    ListSitesIntervalEnum["Last2Hours"] = "last2Hours";
    ListSitesIntervalEnum["LastDay"] = "lastDay";
    ListSitesIntervalEnum["LastWeek"] = "lastWeek";
    ListSitesIntervalEnum["LastMonth"] = "lastMonth";
})(ListSitesIntervalEnum = exports.ListSitesIntervalEnum || (exports.ListSitesIntervalEnum = {}));
