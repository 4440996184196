"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class TagApi extends runtime.BaseAPI {
    /**
     * Gets the details of a single instance of a `Tag` by slug.
     * Get tag by slug
     */
    async getTagBySlugRaw(requestParameters, initOverrides) {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug', 'Required parameter requestParameters.slug was null or undefined when calling getTagBySlug.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/tag/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.TagResultFromJSON)(jsonValue));
    }
    /**
     * Gets the details of a single instance of a `Tag` by slug.
     * Get tag by slug
     */
    async getTagBySlug(requestParameters, initOverrides) {
        const response = await this.getTagBySlugRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
exports.TagApi = TagApi;
