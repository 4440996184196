"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadImageUrlsToJSONTyped = exports.LeadImageUrlsToJSON = exports.LeadImageUrlsFromJSONTyped = exports.LeadImageUrlsFromJSON = void 0;
const runtime_1 = require("../runtime");
function LeadImageUrlsFromJSON(json) {
    return LeadImageUrlsFromJSONTyped(json, false);
}
exports.LeadImageUrlsFromJSON = LeadImageUrlsFromJSON;
function LeadImageUrlsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'leadImageUrl': !(0, runtime_1.exists)(json, 'leadImageUrl') ? undefined : json['leadImageUrl'],
        'leadImageUrl11': !(0, runtime_1.exists)(json, 'leadImageUrl_1-1') ? undefined : json['leadImageUrl_1-1'],
        'leadImageUrl23': !(0, runtime_1.exists)(json, 'leadImageUrl_2-3') ? undefined : json['leadImageUrl_2-3'],
        'leadImageUrl43': !(0, runtime_1.exists)(json, 'leadImageUrl_4-3') ? undefined : json['leadImageUrl_4-3'],
        'leadImageUrl27': !(0, runtime_1.exists)(json, 'leadImageUrl_2-7') ? undefined : json['leadImageUrl_2-7'],
        'leadImageUrl616': !(0, runtime_1.exists)(json, 'leadImageUrl_6-16') ? undefined : json['leadImageUrl_6-16'],
        'leadImageUrl916': !(0, runtime_1.exists)(json, 'leadImageUrl_9-16') ? undefined : json['leadImageUrl_9-16'],
        'leadImageUrl34': !(0, runtime_1.exists)(json, 'leadImageUrl_3-4') ? undefined : json['leadImageUrl_3-4'],
        'leadImageUrl72': !(0, runtime_1.exists)(json, 'leadImageUrl_7-2') ? undefined : json['leadImageUrl_7-2'],
        'leadImageUrl166': !(0, runtime_1.exists)(json, 'leadImageUrl_16-6') ? undefined : json['leadImageUrl_16-6'],
        'leadImageUrl169': !(0, runtime_1.exists)(json, 'leadImageUrl_16-9') ? undefined : json['leadImageUrl_16-9'],
    };
}
exports.LeadImageUrlsFromJSONTyped = LeadImageUrlsFromJSONTyped;
function LeadImageUrlsToJSON(value) {
    return LeadImageUrlsToJSONTyped(value, false);
}
exports.LeadImageUrlsToJSON = LeadImageUrlsToJSON;
function LeadImageUrlsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'leadImageUrl': value.leadImageUrl,
        'leadImageUrl_1-1': value.leadImageUrl11,
        'leadImageUrl_2-3': value.leadImageUrl23,
        'leadImageUrl_4-3': value.leadImageUrl43,
        'leadImageUrl_2-7': value.leadImageUrl27,
        'leadImageUrl_6-16': value.leadImageUrl616,
        'leadImageUrl_9-16': value.leadImageUrl916,
        'leadImageUrl_3-4': value.leadImageUrl34,
        'leadImageUrl_7-2': value.leadImageUrl72,
        'leadImageUrl_16-6': value.leadImageUrl166,
        'leadImageUrl_16-9': value.leadImageUrl169,
    };
}
exports.LeadImageUrlsToJSONTyped = LeadImageUrlsToJSONTyped;
