"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorDataToJSONTyped = exports.ErrorDataToJSON = exports.ErrorDataFromJSONTyped = exports.ErrorDataFromJSON = void 0;
const runtime_1 = require("../runtime");
function ErrorDataFromJSON(json) {
    return ErrorDataFromJSONTyped(json, false);
}
exports.ErrorDataFromJSON = ErrorDataFromJSON;
function ErrorDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'message': !(0, runtime_1.exists)(json, 'message') ? undefined : json['message'],
        'code': !(0, runtime_1.exists)(json, 'code') ? undefined : json['code'],
        'status': !(0, runtime_1.exists)(json, 'status') ? undefined : json['status'],
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : json['type'],
        'params': !(0, runtime_1.exists)(json, 'params') ? undefined : json['params'],
    };
}
exports.ErrorDataFromJSONTyped = ErrorDataFromJSONTyped;
function ErrorDataToJSON(value) {
    return ErrorDataToJSONTyped(value, false);
}
exports.ErrorDataToJSON = ErrorDataToJSON;
function ErrorDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'message': value.message,
        'code': value.code,
        'status': value.status,
        'type': value.type,
        'params': value.params,
    };
}
exports.ErrorDataToJSONTyped = ErrorDataToJSONTyped;
