"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeadingTagToJSONTyped = exports.HeadingTagToJSON = exports.HeadingTagFromJSONTyped = exports.HeadingTagFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function HeadingTagFromJSON(json) {
    return HeadingTagFromJSONTyped(json, false);
}
exports.HeadingTagFromJSON = HeadingTagFromJSON;
function HeadingTagFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'tag': !(0, runtime_1.exists)(json, 'tag') ? undefined : json['tag'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'tagColor': !(0, runtime_1.exists)(json, 'tagColor') ? undefined : json['tagColor'],
        'slug': !(0, runtime_1.exists)(json, 'slug') ? undefined : json['slug'],
        'site': !(0, runtime_1.exists)(json, 'site') ? undefined : (0, _1.MinimalSiteDataFromJSON)(json['site']),
    };
}
exports.HeadingTagFromJSONTyped = HeadingTagFromJSONTyped;
function HeadingTagToJSON(value) {
    return HeadingTagToJSONTyped(value, false);
}
exports.HeadingTagToJSON = HeadingTagToJSON;
function HeadingTagToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'tag': value.tag,
        'url': value.url,
        'tagColor': value.tagColor,
        'slug': value.slug,
        'site': (0, _1.MinimalSiteDataToJSON)(value.site),
    };
}
exports.HeadingTagToJSONTyped = HeadingTagToJSONTyped;
