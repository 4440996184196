"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackgroundImageUrlsToJSONTyped = exports.BackgroundImageUrlsToJSON = exports.BackgroundImageUrlsFromJSONTyped = exports.BackgroundImageUrlsFromJSON = void 0;
const runtime_1 = require("../runtime");
function BackgroundImageUrlsFromJSON(json) {
    return BackgroundImageUrlsFromJSONTyped(json, false);
}
exports.BackgroundImageUrlsFromJSON = BackgroundImageUrlsFromJSON;
function BackgroundImageUrlsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'backgroundImageUrl': !(0, runtime_1.exists)(json, 'backgroundImageUrl') ? undefined : json['backgroundImageUrl'],
        'backgroundImageUrl169': !(0, runtime_1.exists)(json, 'backgroundImageUrl_16-9') ? undefined : json['backgroundImageUrl_16-9'],
        'backgroundImageUrl166': !(0, runtime_1.exists)(json, 'backgroundImageUrl_16-6') ? undefined : json['backgroundImageUrl_16-6'],
        'backgroundImageUrl72': !(0, runtime_1.exists)(json, 'backgroundImageUrl_7-2') ? undefined : json['backgroundImageUrl_7-2'],
        'backgroundImageUrl34': !(0, runtime_1.exists)(json, 'backgroundImageUrl_3-4') ? undefined : json['backgroundImageUrl_3-4'],
        'backgroundImageUrl43': !(0, runtime_1.exists)(json, 'backgroundImageUrl_4-3') ? undefined : json['backgroundImageUrl_4-3'],
        'backgroundImageUrl11': !(0, runtime_1.exists)(json, 'backgroundImageUrl_1-1') ? undefined : json['backgroundImageUrl_1-1'],
    };
}
exports.BackgroundImageUrlsFromJSONTyped = BackgroundImageUrlsFromJSONTyped;
function BackgroundImageUrlsToJSON(value) {
    return BackgroundImageUrlsToJSONTyped(value, false);
}
exports.BackgroundImageUrlsToJSON = BackgroundImageUrlsToJSON;
function BackgroundImageUrlsToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'backgroundImageUrl': value.backgroundImageUrl,
        'backgroundImageUrl_16-9': value.backgroundImageUrl169,
        'backgroundImageUrl_16-6': value.backgroundImageUrl166,
        'backgroundImageUrl_7-2': value.backgroundImageUrl72,
        'backgroundImageUrl_3-4': value.backgroundImageUrl34,
        'backgroundImageUrl_4-3': value.backgroundImageUrl43,
        'backgroundImageUrl_1-1': value.backgroundImageUrl11,
    };
}
exports.BackgroundImageUrlsToJSONTyped = BackgroundImageUrlsToJSONTyped;
