"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageDataToJSONTyped = exports.PageDataToJSON = exports.PageDataFromJSONTyped = exports.PageDataFromJSON = exports.PageDataResponseTypeEnum = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
/**
* @export
* @enum {string}
*/
var PageDataResponseTypeEnum;
(function (PageDataResponseTypeEnum) {
    PageDataResponseTypeEnum["Page"] = "page";
    PageDataResponseTypeEnum["Content"] = "content";
})(PageDataResponseTypeEnum = exports.PageDataResponseTypeEnum || (exports.PageDataResponseTypeEnum = {}));
function PageDataFromJSON(json) {
    return PageDataFromJSONTyped(json, false);
}
exports.PageDataFromJSON = PageDataFromJSON;
function PageDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'redirectUrl': !(0, runtime_1.exists)(json, 'redirectUrl') ? undefined : json['redirectUrl'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'label': !(0, runtime_1.exists)(json, 'label') ? undefined : json['label'],
        'sitemap': !(0, runtime_1.exists)(json, 'sitemap') ? undefined : json['sitemap'],
        'active': !(0, runtime_1.exists)(json, 'active') ? undefined : json['active'],
        'site': !(0, runtime_1.exists)(json, 'site') ? undefined : (0, _1.SiteDataFromJSON)(json['site']),
        'metaTags': !(0, runtime_1.exists)(json, 'metaTags') ? undefined : (json['metaTags'].map(_1.MetaTagFromJSON)),
        'adoKeys': !(0, runtime_1.exists)(json, 'adoKeys') ? undefined : ((0, runtime_1.mapValues)(json['adoKeys'], _1.AdoKeysMediaFromJSON)),
        'trackingVariables': !(0, runtime_1.exists)(json, 'trackingVariables') ? undefined : (0, _1.TrackingVariablesFromJSON)(json['trackingVariables']),
        'folderParameters': !(0, runtime_1.exists)(json, 'folderParameters') ? undefined : json['folderParameters'],
        'menu': !(0, runtime_1.exists)(json, 'menu') ? undefined : (0, _1.MenusFromJSON)(json['menu']),
        'skeleton': !(0, runtime_1.exists)(json, 'skeleton') ? undefined : (json['skeleton'].map(_1.BoxFromJSON)),
        'folderId': !(0, runtime_1.exists)(json, 'folderId') ? undefined : json['folderId'],
        'contentId': !(0, runtime_1.exists)(json, 'contentId') ? undefined : json['contentId'],
        'pageId': json['pageId'],
        'layoutId': !(0, runtime_1.exists)(json, 'layoutId') ? undefined : json['layoutId'],
        'isConnectedWithLayout': !(0, runtime_1.exists)(json, 'isConnectedWithLayout') ? undefined : json['isConnectedWithLayout'],
        'contentTag': !(0, runtime_1.exists)(json, 'contentTag') ? undefined : json['contentTag'],
        'redirectStatus': !(0, runtime_1.exists)(json, 'redirectStatus') ? undefined : json['redirectStatus'],
        'castTag': !(0, runtime_1.exists)(json, 'castTag') ? undefined : json['castTag'],
        'layoutFile': !(0, runtime_1.exists)(json, 'layoutFile') ? undefined : json['layoutFile'],
        'responseType': !(0, runtime_1.exists)(json, 'responseType') ? undefined : json['responseType'],
        'html': !(0, runtime_1.exists)(json, 'html') ? undefined : json['html'],
        'authorSlug': !(0, runtime_1.exists)(json, 'authorSlug') ? undefined : json['authorSlug'],
    };
}
exports.PageDataFromJSONTyped = PageDataFromJSONTyped;
function PageDataToJSON(value) {
    return PageDataToJSONTyped(value, false);
}
exports.PageDataToJSON = PageDataToJSON;
function PageDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'redirectUrl': value.redirectUrl,
        'url': value.url,
        'label': value.label,
        'sitemap': value.sitemap,
        'active': value.active,
        'site': (0, _1.SiteDataToJSON)(value.site),
        'metaTags': value.metaTags === undefined ? undefined : (value.metaTags.map(_1.MetaTagToJSON)),
        'adoKeys': value.adoKeys === undefined ? undefined : ((0, runtime_1.mapValues)(value.adoKeys, _1.AdoKeysMediaToJSON)),
        'trackingVariables': (0, _1.TrackingVariablesToJSON)(value.trackingVariables),
        'folderParameters': value.folderParameters,
        'menu': (0, _1.MenusToJSON)(value.menu),
        'skeleton': value.skeleton === undefined ? undefined : (value.skeleton.map(_1.BoxToJSON)),
        'folderId': value.folderId,
        'contentId': value.contentId,
        'pageId': value.pageId,
        'layoutId': value.layoutId,
        'isConnectedWithLayout': value.isConnectedWithLayout,
        'contentTag': value.contentTag,
        'redirectStatus': value.redirectStatus,
        'castTag': value.castTag,
        'layoutFile': value.layoutFile,
        'responseType': value.responseType,
        'html': value.html,
        'authorSlug': value.authorSlug,
    };
}
exports.PageDataToJSONTyped = PageDataToJSONTyped;
