"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminPagePutBodyToJSONTyped = exports.AdminPagePutBodyToJSON = exports.AdminPagePutBodyFromJSONTyped = exports.AdminPagePutBodyFromJSON = void 0;
const _1 = require("./");
function AdminPagePutBodyFromJSON(json) {
    return AdminPagePutBodyFromJSONTyped(json, false);
}
exports.AdminPagePutBodyFromJSON = AdminPagePutBodyFromJSON;
function AdminPagePutBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'data': (0, _1.AdminPageDataFromJSON)(json['data']),
    };
}
exports.AdminPagePutBodyFromJSONTyped = AdminPagePutBodyFromJSONTyped;
function AdminPagePutBodyToJSON(value) {
    return AdminPagePutBodyToJSONTyped(value, false);
}
exports.AdminPagePutBodyToJSON = AdminPagePutBodyToJSON;
function AdminPagePutBodyToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'data': (0, _1.AdminPageDataToJSON)(value.data),
    };
}
exports.AdminPagePutBodyToJSONTyped = AdminPagePutBodyToJSONTyped;
