"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PostCastingFormDataToJSONTyped = exports.PostCastingFormDataToJSON = exports.PostCastingFormDataFromJSONTyped = exports.PostCastingFormDataFromJSON = void 0;
const runtime_1 = require("../runtime");
function PostCastingFormDataFromJSON(json) {
    return PostCastingFormDataFromJSONTyped(json, false);
}
exports.PostCastingFormDataFromJSON = PostCastingFormDataFromJSON;
function PostCastingFormDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'formCode': json['formCode'],
        'data': json['data'],
        'upload': !(0, runtime_1.exists)(json, 'upload') ? undefined : json['upload'],
        'upload1': !(0, runtime_1.exists)(json, 'upload1') ? undefined : json['upload1'],
        'upload2': !(0, runtime_1.exists)(json, 'upload2') ? undefined : json['upload2'],
        'upload3': !(0, runtime_1.exists)(json, 'upload3') ? undefined : json['upload3'],
        'upload4': !(0, runtime_1.exists)(json, 'upload4') ? undefined : json['upload4'],
        'upload5': !(0, runtime_1.exists)(json, 'upload5') ? undefined : json['upload5'],
    };
}
exports.PostCastingFormDataFromJSONTyped = PostCastingFormDataFromJSONTyped;
function PostCastingFormDataToJSON(value) {
    return PostCastingFormDataToJSONTyped(value, false);
}
exports.PostCastingFormDataToJSON = PostCastingFormDataToJSON;
function PostCastingFormDataToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'formCode': value.formCode,
        'data': value.data,
        'upload': value.upload,
        'upload1': value.upload1,
        'upload2': value.upload2,
        'upload3': value.upload3,
        'upload4': value.upload4,
        'upload5': value.upload5,
    };
}
exports.PostCastingFormDataToJSONTyped = PostCastingFormDataToJSONTyped;
