"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuToJSONTyped = exports.MenuToJSON = exports.MenuFromJSONTyped = exports.MenuFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function MenuFromJSON(json) {
    return MenuFromJSONTyped(json, false);
}
exports.MenuFromJSON = MenuFromJSON;
function MenuFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'label': !(0, runtime_1.exists)(json, 'label') ? undefined : json['label'],
        'menuItems': !(0, runtime_1.exists)(json, 'menuItems') ? undefined : (json['menuItems'].map(_1.MenuItemFromJSON)),
    };
}
exports.MenuFromJSONTyped = MenuFromJSONTyped;
function MenuToJSON(value) {
    return MenuToJSONTyped(value, false);
}
exports.MenuToJSON = MenuToJSON;
function MenuToJSONTyped(value, ignoreDiscriminator = false) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'label': value.label,
        'menuItems': value.menuItems === undefined ? undefined : (value.menuItems.map(_1.MenuItemToJSON)),
    };
}
exports.MenuToJSONTyped = MenuToJSONTyped;
