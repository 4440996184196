"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayLoadContentTypeToJSONTyped = exports.PayLoadContentTypeToJSON = exports.PayLoadContentTypeFromJSONTyped = exports.PayLoadContentTypeFromJSON = exports.PayLoadContentType = void 0;
/**
 * ContentType for PayLoad
 * @export
 * @enum {string}
 */
var PayLoadContentType;
(function (PayLoadContentType) {
    PayLoadContentType["News"] = "news";
    PayLoadContentType["Case"] = "case";
    PayLoadContentType["Celeb"] = "celeb";
    PayLoadContentType["FolderContent"] = "folder_content";
    PayLoadContentType["Gallery"] = "gallery";
    PayLoadContentType["Longvideo"] = "longvideo";
    PayLoadContentType["Quiz"] = "quiz";
    PayLoadContentType["Vote"] = "vote";
    PayLoadContentType["Promo"] = "promo";
    PayLoadContentType["Character"] = "character";
})(PayLoadContentType = exports.PayLoadContentType || (exports.PayLoadContentType = {}));
function PayLoadContentTypeFromJSON(json) {
    return PayLoadContentTypeFromJSONTyped(json, false);
}
exports.PayLoadContentTypeFromJSON = PayLoadContentTypeFromJSON;
function PayLoadContentTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.PayLoadContentTypeFromJSONTyped = PayLoadContentTypeFromJSONTyped;
function PayLoadContentTypeToJSON(value) {
    return PayLoadContentTypeToJSONTyped(value, false);
}
exports.PayLoadContentTypeToJSON = PayLoadContentTypeToJSON;
function PayLoadContentTypeToJSONTyped(value, ignoreDiscriminator = false) {
    return value;
}
exports.PayLoadContentTypeToJSONTyped = PayLoadContentTypeToJSONTyped;
