"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResultTypeToJSONTyped = exports.ResultTypeToJSON = exports.ResultTypeFromJSONTyped = exports.ResultTypeFromJSON = exports.ResultType = void 0;
/**
 * Type of result
 * @export
 * @enum {string}
 */
var ResultType;
(function (ResultType) {
    ResultType["Found"] = "found";
    ResultType["Error"] = "error";
    ResultType["Redirect"] = "redirect";
    ResultType["Fallback"] = "fallback";
    ResultType["Success"] = "success";
})(ResultType = exports.ResultType || (exports.ResultType = {}));
function ResultTypeFromJSON(json) {
    return ResultTypeFromJSONTyped(json, false);
}
exports.ResultTypeFromJSON = ResultTypeFromJSON;
function ResultTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ResultTypeFromJSONTyped = ResultTypeFromJSONTyped;
function ResultTypeToJSON(value) {
    return ResultTypeToJSONTyped(value, false);
}
exports.ResultTypeToJSON = ResultTypeToJSON;
function ResultTypeToJSONTyped(value, ignoreDiscriminator = false) {
    return value;
}
exports.ResultTypeToJSONTyped = ResultTypeToJSONTyped;
