"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RTL 2021 API (2024.03.19.)
 * RTL 2021 API (2024.03.19.)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: rtlteam@konzorcia.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthorApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class AuthorApi extends runtime.BaseAPI {
    /**
     * Gets the details of a single instance of a `Author`.
     * Get an Author
     */
    async getAuthorBySlugRaw(requestParameters, initOverrides) {
        if (requestParameters.authorSlug === null || requestParameters.authorSlug === undefined) {
            throw new runtime.RequiredError('authorSlug', 'Required parameter requestParameters.authorSlug was null or undefined when calling getAuthorBySlug.');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/author/{authorSlug}`.replace(`{${"authorSlug"}}`, encodeURIComponent(String(requestParameters.authorSlug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.AuthorResultFromJSON)(jsonValue));
    }
    /**
     * Gets the details of a single instance of a `Author`.
     * Get an Author
     */
    async getAuthorBySlug(requestParameters, initOverrides) {
        const response = await this.getAuthorBySlugRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Gets list of Authors.
     * List default authors
     */
    async listDefaultAuthorsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.excludedAuthorIds) {
            queryParameters['excludedAuthorIds'] = requestParameters.excludedAuthorIds;
        }
        if (requestParameters.excludedAuthors) {
            queryParameters['excludedAuthors'] = requestParameters.excludedAuthors;
        }
        if (requestParameters.order) {
            queryParameters['order'] = requestParameters.order;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }
        const headerParameters = {};
        if (requestParameters.requestReferer !== undefined && requestParameters.requestReferer !== null) {
            headerParameters['Request-Referer'] = String(requestParameters.requestReferer);
        }
        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/author`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.AuthorListResultFromJSON)(jsonValue));
    }
    /**
     * Gets list of Authors.
     * List default authors
     */
    async listDefaultAuthors(requestParameters, initOverrides) {
        const response = await this.listDefaultAuthorsRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
exports.AuthorApi = AuthorApi;
